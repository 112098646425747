import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer, Box } from "@material-ui/core";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { SidebarNav } from "./components";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    // [theme.breakpoints.up("lg")]: {
    //   marginTop: 64,
    // height: "calc(100% - 64px)",
    // },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  chevron: {
    float: "left",
  },
}));

const Sidebar = (props) => {
  const { open, variant, onClose, className, handleSubmit } = props;

  const classes = useStyles();

  const pages = [
    {
      title: "Dashboard",
      href: "/",
      icon: <DashboardIcon />,
    },
    {
      title: "Reporting",
      href: "/reporting",
      icon: <AssessmentIcon />,
    },
  ];

  return (
    <Drawer
      anchor='right'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <Box className={clsx(classes.root, className)}>
        <Divider className={classes.divider} />
        <SidebarNav
          className={classes.nav}
          pages={pages}
          handleSubmit={handleSubmit}
        />
      </Box>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

export default Sidebar;
