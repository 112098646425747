import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Input,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {},
  filterContainer: {
    position: "relative",
    marginBottom: 25,
  },
  formControl: {
    width: "100%",
  },
}));

const Selector = ({ filters, categories, countries, setFilters }) => {
  const classes = useStyles();

  const handleChange = event => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const getCategories = category =>
    categories.find(val => val.name === category);

  return (
    <div className={classes.filterContainer}>
      <form autoComplete="off" noValidate style={{ marginBottom: 10 }}>
        <Grid container spacing={4} justify="center">
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography gutterBottom variant="body2">
              SELECT A COUNTRY AND START EXPLORING
            </Typography>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="country-label">Country</InputLabel>
              <Select
                fullWidth
                id="country-label"
                input={<Input required />}
                name="country"
                onChange={handleChange}
                value={filters.country}
              >
                {countries.map(country => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id="category-label">Category</InputLabel>
              <Select
                fullWidth
                id="category-label"
                input={<Input required variant="outlined" />}
                name="category"
                onChange={handleChange}
                value={filters.category}
                variant="outlined"
              >
                {categories.map(category => (
                  <MenuItem key={category.name} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={2} sm={6} xl={2} xs={12}>
            <Button
              color="primary"
              variant="outlined"
              fullWidth
              component={RouterLink}
              to={{
                pathname: "/dashboard",
                state: {
                  category: getCategories(filters.category),
                  country: filters.country,
                },
              }}
            >
              GO
            </Button>
          </Grid>
          <Grid item lg={12} sm={12} xl={12} xs={12}>
            <Typography gutterBottom variant="body2">
              WORKING WITH THOUSANDS OF THE WORLD’S BIGGEST BRANDS
            </Typography>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

Selector.propTypes = {
  categories: PropTypes.array,
  countries: PropTypes.array,
  setFilters: PropTypes.func,
  filters: PropTypes.object,
};

export default Selector;
