import React from "react";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { apiUrl } from "api/api";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
const { default: axios } = require("axios");

const useStyles = makeStyles({
  root: {
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  textfieldClass: {
    "& .MuiOutlinedInput-input": {
      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
      },
    },
  },
});

function Login(props) {
  const [error, setError] = React.useState("");
  const classes = useStyles();
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = new FormData(e.currentTarget);

    const getData = async () => {
      let user, login;

      try {
        let userAuthData = data.get("pin");
        login = false;
        user = window.btoa(userAuthData + ":" + userAuthData);
        await axios
          .get(`${apiUrl.testUrl}/client/login`, {
            headers: { Authorization: `Basic ${user}` },
          })
          .then((res) => {
            userAuthData = res.data.client;

            if (res.status === 200) {
              login = true;
              localStorage.setItem("user", JSON.stringify(user));
            }
          });
      } catch (error) {
        setError("Error logging in!");
      }

      if (login) {
        const { from } = props.location.state || {
          from: { pathname: "/reporting" },
        };
        props.history.push(from);
      } else {
        return;
      }
    };

    return getData();
  };

  return (
    <>
      <Container maxWidth='xs'>
        <Grid container spacing={2} className={classes.root}>
          <Grid item>
            <Typography color='textPrimary' variant='h4'>
              Sign in
            </Typography>
            <Typography color='textSecondary' gutterBottom variant='body2'>
              Sign in to the campaign page
            </Typography>
          </Grid>
          <Grid item component='form' onSubmit={handleSubmit} noValidate>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              id='pin'
              color='secondary'
              label='Pin'
              name='pin'
              type='number'
              className={classes.textfieldClass}
            />
            {error ? <Alert severity='error'>{error}</Alert> : null}
            <br />
            <Button type='submit' variant='contained' fullWidth>
              Sign in
            </Button>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default Login;
