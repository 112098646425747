import { PDFDocument } from "pdf-lib";
// eslint-disable-next-line no-unused-vars
import React from "react";

import download from "downloadjs";
import html2canvas from "html2canvas";

export async function createPDF(item) {
  const jpgUrl =
    "https://yt3.ggpht.com/7RhdgPOeFZzx_iC6J5zhROTcEM1vLEE-05lUNsTe3ku5mmgHrsV066UuocHw9A05SOO4eKmp=s900-c-k-c0x00ffffff-no-rj";

  const jpgImageBytes = await fetch(jpgUrl).then(res => res.arrayBuffer());

  const pdfDoc = await PDFDocument.create();

  const jpgImage = await pdfDoc.embedJpg(jpgImageBytes);

  const jpgDims = jpgImage.scale(0.1);

  const page = pdfDoc.addPage();

  page.drawImage(jpgImage, {
    x: page.getWidth() / 2 - jpgDims.width / 2,
    y: page.getHeight() / 2 - jpgDims.height / 2 + 400,
    width: jpgDims.width,
    height: jpgDims.height,
  });

  if (item) {
    const element = await item[0];
    const element2 = await item[1];
    const element3 = await item[2];
    const element4 = await item[3];
    const element5 = await item[4];

    const elementCanvas = await html2canvas(element);
    const elementData = elementCanvas.toDataURL("image/png");

    const pngImage = await pdfDoc.embedPng(elementData);

    const elementCanvas2 = await html2canvas(element2);
    const elementData2 = elementCanvas2.toDataURL("image/png");

    const pngImage2 = await pdfDoc.embedPng(elementData2);

    const elementCanvas3 = await html2canvas(element3);
    const elementData3 = elementCanvas3.toDataURL("image/png");

    const pngImage3 = await pdfDoc.embedPng(elementData3);

    const elementCanvas4 = await html2canvas(element4);
    const elementData4 = elementCanvas4.toDataURL("image/png");

    const pngImage4 = await pdfDoc.embedPng(elementData4);

    const elementCanvas5 = await html2canvas(element5);
    const elementData5 = elementCanvas5.toDataURL("image/png");

    const pngImage5 = await pdfDoc.embedPng(elementData5);

    const jpgDims1 = pngImage.scale(0.25);
    const jpgDims2 = pngImage2.scale(0.25);
    const jpgDims3 = pngImage3.scale(0.25);
    const jpgDims4 = pngImage4.scale(0.25);
    const jpgDims5 = pngImage5.scale(0.25);

    page.setWidth(page.getWidth() + 50);

    if (pngImage.height <= 791) {
      page.drawImage(pngImage, {
        x: page.getWidth() / 2 - jpgDims1.width / 2,
        y: page.getHeight() / 1 - jpgDims1.height / 1 - 80,
        width: jpgDims1.width,
        height: jpgDims1.height,
      });

      page.drawImage(pngImage2, {
        x: page.getWidth() / 2 - jpgDims2.width / 2,
        y: page.getHeight() / 1 - jpgDims2.height / 1 - 290,
        width: jpgDims2.width,
        height: jpgDims2.height,
      });

      page.drawImage(pngImage3, {
        x: page.getWidth() / 2 - jpgDims3.width / 2,
        y: page.getHeight() / 1 - jpgDims3.height / 1 - 440,
        width: jpgDims3.width,
        height: jpgDims3.height,
      });

      page.drawImage(pngImage4, {
        x: page.getWidth() / 2 - jpgDims4.width / 2,
        y: page.getHeight() / 1 - jpgDims4.height / 1 - 600,
        width: jpgDims4.width,
        height: jpgDims4.height,
      });
    } else if (pngImage.height >= 1502) {
      page.drawImage(pngImage, {
        x: page.getWidth() / 2 - jpgDims1.width / 2,
        y: page.getHeight() / 1 - jpgDims1.height / 1 - 80,
        width: jpgDims1.width,
        height: jpgDims1.height,
      });
    }

    // Brand performance and market penetration
    if (pngImage.height >= 1502) {
      const pg = pdfDoc.addPage();
      pg.setWidth(pg.getWidth() + 50);
      const jpgDims2 = pngImage2.scale(0.25);
      const jpgDims3 = pngImage3.scale(0.25);

      pg.drawImage(pngImage2, {
        x: pg.getWidth() / 2 - jpgDims2.width / 2,
        y: pg.getHeight() / 1 - jpgDims2.height / 1 - 250,
        width: jpgDims2.width,
        height: jpgDims2.height,
      });

      pg.drawImage(pngImage3, {
        x: pg.getWidth() / 2 - jpgDims3.width / 2,
        y: pg.getHeight() / 1 - jpgDims3.height / 1 - 400,
        width: jpgDims3.width,
        height: jpgDims3.height,
      });

      pg.drawText("www.mdundoforbrands.com", {
        x: pg.getWidth() / 2 - 80,
        y: 10,
        size: 12,
      });
    }

    if (pngImage4.height > 925) {
      const pg = pdfDoc.addPage();
      pg.setWidth(pg.getWidth() + 50);
      const jpgDims4 = pngImage4.scale(0.17);

      pg.drawImage(pngImage4, {
        x: pg.getWidth() / 2 - jpgDims4.width / 2,
        y: pg.getHeight() / 1 - jpgDims4.height / 1 - 0,
        width: jpgDims4.width,
        height: jpgDims4.height,
      });

      pg.drawText("www.mdundoforbrands.com", {
        x: pg.getWidth() / 2 - 80,
        y: 10,
        size: 12,
      });
    }

    page.drawText("www.mdundoforbrands.com", {
      x: page.getWidth() / 2 - 80,
      y: 10,
      size: 12,
    });

    const page2 = pdfDoc.addPage();
    page2.setWidth(page2.getWidth() + 50);
    page2.drawText("www.mdundoforbrands.com", {
      x: page2.getWidth() / 2 - 80,
      y: 10,
      size: 12,
    });

    page2.drawImage(pngImage5, {
      x: page2.getWidth() / 2 - jpgDims5.width / 2,
      y: page2.getHeight() / 1 - jpgDims5.height / 1 - 50,
      width: jpgDims5.width,
      height: jpgDims5.height,
    });

    const pdfBytes = await pdfDoc.save();
    download(pdfBytes, "mdundo_for_brands.pdf");
  }
}
