import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import {
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Input,
  Checkbox,
  ListItemText,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
// import DateFnsUtils from "@date-io/date-fns";
// import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import {
  ageBrackets,
  locations,
  lsmOptions,
  countries,
  categories,
  gender,
  months,
  productCount,
  metrics,
} from "../../../../data";
import { eventTracker } from "analytics";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.button,
    color: theme.palette.text.primary,
    padding: theme.spacing(1),
    width: "fit-content",
    cursor: "pointer",
    // border: "2px solid #1D1D1D52",
    borderRadius: "5px",
    fontWeight: 400,
    fontSize: "12px",
    background: "#9999993E",
  },
  filterContainer: {
    position: "relative",
    margin: "1rem 0",
  },
  formControl: {
    width: "100%",
  },
}));

const ReportFilter = ({ filters, setFilters, getReport }) => {
  const history = useHistory();
  const { search } = useLocation();

  const classes = useStyles();
  const handleChange = (event) => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value,
    });
  };

  const [category, setCategory] = React.useState("");
  const [products, setProducts] = React.useState([]);
  const [advanced, setAdvanced] = React.useState(false);

  // const handleDateChange = (name, value) => {
  //   const ev = {
  //     target: {
  //       name,
  //       value
  //     }
  //   }
  //   handleChange(ev);
  // }

  const [reload, setReload] = React.useState(false);

  useEffect(() => {
    if (category) {
      const selectedProducts = getProducts(category);
      setProducts(selectedProducts.subcategories);
    }
    const params = new URLSearchParams();
    if (
      filters.productName ||
      filters.country ||
      filters.months ||
      filters.productCount ||
      filters.metrics
    ) {
      setReload(false);
      if (filters.productName) {
        params.append("product", filters.productName);
      } else {
        params.delete("product");
      }
      if (filters.country) {
        params.append("country", filters.country);
      } else {
        params.delete("country");
      }
      if (filters.months) {
        params.append("month", filters.months);
      } else {
        params.delete("month");
      }
      if (filters.productCount) {
        params.append("top_n", filters.productCount);
      } else {
        params.delete("top_n");
      }
      if (filters.metrics) {
        params.append("metric", filters.metrics);
      } else {
        params.delete("metric");
      }
    } else {
      if (search) {
        setReload(true);
        const query = getQueryStringParams(search);
        if (query.product) {
          const dt = getCategory(query.product);
          if (dt.length > 0) {
            setCategory(dt[0].name);
            setProducts(dt[0].subcategories);
          }
        }
        setFilters({
          ...filters,
          productName: query.product || "",
          country: query.country || "",
          months: query.month || "",
          productCount: query.top_n || "",
          metrics: query.metric || "",
        });
      }
    }
    history.push({ search: params.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, history, search, category]);

  useEffect(() => {
    if (reload) {
      setTimeout(() => {
        if (
          filters.productName ||
          filters.country ||
          filters.months ||
          filters.productCount ||
          filters.metrics
        ) {
          getReport();
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, filters]);

  const getQueryStringParams = (query) => {
    return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split("&")
          .reduce((params, param) => {
            let [key, value] = param.split("=");
            params[key] = value
              ? decodeURIComponent(value?.replace(/\+/g, " "))
              : "";
            return params;
          }, {})
      : {};
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const getProducts = (category) =>
    categories.find((val) => val.name === category);
  const getCategory = (product) =>
    categories.filter((val) =>
      val.subcategories.find((sub) => sub.key === product)
    );

  return (
    <div className={classes.filterContainer}>
      <Typography gutterBottom variant='h3'>
        Select category, product and country
      </Typography>
      <form autoComplete='off' noValidate style={{ marginBottom: 10 }}>
        <Grid container spacing={4}>
          <Grid item lg={4} sm={6} xl={4} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id='category-label'>Category</InputLabel>
              <Select
                fullWidth
                id='category-label'
                input={<Input required variant='outlined' />}
                name='category'
                onChange={handleCategoryChange}
                value={category}
                variant='outlined'
              >
                {categories.map((category) => (
                  <MenuItem key={category.name} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={4} sm={6} xl={4} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id='product-label'>Product</InputLabel>
              <Select
                fullWidth
                id='product'
                input={<Input required />}
                name='productName'
                onChange={handleChange}
                value={filters.productName}
              >
                {products.map((product) => (
                  <MenuItem key={product.key} value={product.key}>
                    {product.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={4} sm={6} xl={4} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id='country-label'>Country</InputLabel>
              <Select
                fullWidth
                id='country-label'
                input={<Input required />}
                name='country'
                onChange={handleChange}
                value={filters.country}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {advanced ? (
            <>
              <Grid item lg={4} sm={6} xl={4} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id='location-label'>Location</InputLabel>
                  <Select
                    fullWidth
                    id='location-label'
                    input={<Input required />}
                    name='urban_rural'
                    multiple
                    onChange={handleChange}
                    renderValue={(selected) =>
                      selected.map((val) => val).join(", ")
                    }
                    value={filters.urban_rural}
                  >
                    {locations.map((location) => (
                      <MenuItem key={location} value={location}>
                        <Checkbox
                          checked={
                            filters.urban_rural
                              .map((val) => val)
                              .indexOf(location) > -1
                          }
                        />
                        <ListItemText primary={location} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} sm={6} xl={4} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id='age-label'>Age Bracket</InputLabel>
                  <Select
                    fullWidth
                    id='age-label'
                    input={<Input required />}
                    name='age_bracket'
                    multiple
                    onChange={handleChange}
                    renderValue={(selected) =>
                      selected.map((val) => val).join(", ")
                    }
                    value={filters.age_bracket}
                  >
                    {ageBrackets.map((age) => (
                      <MenuItem key={age} value={age}>
                        <Checkbox
                          checked={
                            filters.age_bracket.map((val) => val).indexOf(age) >
                            -1
                          }
                        />
                        <ListItemText primary={age} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} sm={6} xl={4} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id='lsm-label'>LSM</InputLabel>
                  <Select
                    fullWidth
                    id='lsm-label'
                    input={<Input required />}
                    name='lsm'
                    multiple
                    onChange={handleChange}
                    renderValue={(selected) =>
                      selected.map((val) => val.key).join(", ")
                    }
                    value={filters.lsm}
                  >
                    {lsmOptions.map((lsm) => (
                      <MenuItem key={lsm.key} value={lsm}>
                        <Checkbox
                          checked={
                            filters.lsm
                              .map((val) => val.label)
                              .indexOf(lsm.label) > -1
                          }
                        />
                        <ListItemText primary={lsm.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} sm={6} xl={4} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id='gender-label'>Gender</InputLabel>
                  <Select
                    fullWidth
                    id='gender-label'
                    input={<Input required />}
                    name='gender'
                    multiple
                    onChange={handleChange}
                    renderValue={(selected) =>
                      selected.map((val) => val.label).join(", ")
                    }
                    value={filters.gender}
                  >
                    {gender.map((gn) => (
                      <MenuItem key={gn.key} value={gn}>
                        <Checkbox
                          checked={
                            filters.gender
                              .map((val) => val.label)
                              .indexOf(gn.label) > -1
                          }
                        />
                        <ListItemText primary={gn.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} sm={6} xl={4} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id='months'>Period</InputLabel>
                  <Select
                    fullWidth
                    id='months'
                    input={<Input required />}
                    name='months'
                    onChange={handleChange}
                    value={filters.months}
                  >
                    {months.map((month) => (
                      <MenuItem key={month.key} value={month.key}>
                        {month.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} sm={6} xl={4} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id='product-count'>Brand Count</InputLabel>
                  <Select
                    fullWidth
                    id='productCount'
                    input={<Input required />}
                    name='productCount'
                    value={filters.productCount}
                    onChange={handleChange}
                  >
                    {productCount.map((prod) => (
                      <MenuItem key={prod.key} value={prod.key}>
                        {prod.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={4} sm={6} xl={4} xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id='metrics-label'>Audience Metrics</InputLabel>
                  <Select
                    fullWidth
                    id='metrics-label'
                    input={<Input required />}
                    name='metrics'
                    onChange={handleChange}
                    value={filters.metrics}
                  >
                    {metrics.map((metric) => (
                      <MenuItem key={metric.key} value={metric.key}>
                        {metric.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </>
          ) : null}

          {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.formControl}
                disableToolbar
                variant='inline'
                format='MM/dd/yyyy'
                margin='normal'
                id='startDate'
                label='Start Date'
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onChange={(e) => handleDateChange('startDate', e)}
                value={filters.startDate}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item lg={3} sm={6} xl={3} xs={12}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.formControl}
                disableToolbar
                variant='inline'
                format='MM/dd/yyyy'
                margin='normal'
                id='endDate'
                label='End Date'
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                onChange={(e) => handleDateChange('endDate', e)}
                value={filters.endDate}
              />
            </MuiPickersUtilsProvider>
          </Grid> */}
        </Grid>
        <Grid container spacing={4}>
          <Grid item>
            <div
              className={classes.root}
              onClick={() => setAdvanced((prev) => !prev)}
            >
              Advanced Search
            </div>
          </Grid>
          <Grid item>
            <Button
              color='secondary'
              variant='outlined'
              fullWidth
              onClick={() => {
                eventTracker(
                  `${category}`,
                  "User clicked Explore more",
                  `${search}`
                );
                getReport();
              }}
              disabled={!filters.productName}
            >
              Explore Now
            </Button>
          </Grid>
        </Grid>
      </form>
      <br />
      <br />
    </div>
  );
};

ReportFilter.propTypes = {
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  getReport: PropTypes.func,
};

export default ReportFilter;
