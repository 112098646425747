import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Divider, Typography } from "@material-ui/core";
import { Navbar } from "..";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  flexGrow: {
    flexGrow: 1,
  },
  brand: {
    color: "Black",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: 240,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: 240,
  },
  closeMenuButton: {
    marginRight: "auto",
    marginLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
  barContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    color: "white",
    "&>a": {
      width: "60px",
      height: "60px",
      "&>img": {
        width: "100%",
        height: "100%",
        placeItems: "center",
      },
    },
    "&>h5": {
      flex: "70%",
      padding: "0rem",
    },
  },
  // drawer: {
  //   color: "#141414",
  // },
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
    border: "1px solid",
    width: "fit-content",
    padding: "1rem 0",
    "&>a": {
      padding: "1rem 1rem 1rem 0",
      // border: "1px solid",
    },
  },
}));

const Topbar = (props) => {
  const { ...rest } = props;

  const classes = useStyles();

  return (
    <>
      <AppBar style={{ height: "fit-content", position: "sticky" }}>
        <div className={classes.root} {...rest}>
          <div className={classes.barContainer}>
            <RouterLink to='/'>
              <img src='/images/mdundo_logo.png' alt='' />
            </RouterLink>
            <Typography variant='h5' className={classes.brand}>
              Mdundo Brand Lift - Real-Time Data on Brand Performance within the
              Mdundo Audience
            </Typography>
          </div>
          <Divider />
          <Navbar />
        </div>
      </AppBar>
      <AppBar></AppBar>
    </>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
