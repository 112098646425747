const getLabels = (brandlift, topProducts, metric) => {
  let labelSet = new Set(); // hold the date labels
  const brandsMap = new Map(); // hold brandlift data for all brands

  const dateMap = new Map(); // hold data for each date
  brandlift.forEach(element => {
    // set label dates
    labelSet.add(element.date);
    // set data for each brands
    if (dateMap.has(element.date)) {
      const current = dateMap.get(element.date);
      dateMap.set(element.date, [...current, { ...element }]);
    } else {
      dateMap.set(element.date, [{ ...element }]);
    }
    // set brandlift data for all brands
    if (brandsMap.has(element.product_service)) {
      const current = brandsMap.get(element.product_service);
      if (metric === "brandlift" || metric === "") {
        brandsMap.set(element.product_service, [...current, element.brandlift]);
      } else if (metric === "consideration") {
        brandsMap.set(element.product_service, [
          ...current,
          element.consideration,
        ]);
      } else if (metric === "intent") {
        brandsMap.set(element.product_service, [...current, element.intent]);
      } else if (metric === "awareness") {
        brandsMap.set(element.product_service, [...current, element.awareness]);
      } else if (metric === "favourability") {
        brandsMap.set(element.product_service, [
          ...current,
          element.favourability,
        ]);
      }
    } else {
      if (metric === "brandlift" || metric === "") {
        brandsMap.set(element.product_service, [element.brandlift]);
      } else if (metric === "consideration") {
        brandsMap.set(element.product_service, [element.consideration]);
      } else if (metric === "intent") {
        brandsMap.set(element.product_service, [element.intent]);
      } else if (metric === "awareness") {
        brandsMap.set(element.product_service, [element.awareness]);
      } else if (metric === "favourability") {
        brandsMap.set(element.product_service, [element.favourability]);
      }
    }
  });

  const labels = [...labelSet];
  // generate datasets
  const datasets = [];
  topProducts.forEach(key => {
    if (brandsMap.has(key)) {
      const data = [...brandsMap.get(key)];
      datasets.push({
        name: key || "None",
        data: data.map(v => Math.round(v * 100)),
      });
    }
  });
  return {
    labels,
    datasets,
  };
};

export const data = (brandlift, topProducts, metric) => {
  topProducts = [...topProducts];
  //topProducts = [...topProducts, "Others"];
  const summary = getLabels(brandlift, topProducts, metric);

  return {
    labels: summary.labels,
    datasets: summary.datasets,
  };
};
