import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from "@material-ui/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";

import { makeStyles } from "@material-ui/styles";
import { useGet } from "hooks/useGet";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const AudienceDemographic = React.forwardRef(({ filters, className }, ref) => {
  const classes = useStyles();
  const { series, loading, error } = useGet(filters, "ga/audience/demographic");
  return (
    <div className={classes.root}>
      {loading ? (
        <div className={className}>
          <CircularProgress disableShrink />
        </div>
      ) : (
        <Card>
          <CardHeader title="Audience Demographics (%) within the Mdundo Audience, last 7 days" />
          <Divider />
          <CardContent>
            <div ref={ref}>
              <Grid container spacing={4}>
                {series.map((chart, key) => {
                  const options = {
                    chart: {
                      plotBackgroundColor: null,
                      plotBorderWidth: null,
                      plotShadow: false,
                      type: "pie",
                      marginBottom: 100,
                    },
                    credits: {
                      enabled: false,
                    },
                    title: {
                      text: chart.title.toUpperCase(),
                    },
                    tooltip: {
                      headerFormat: null,
                      pointFormat:
                        "{point.name}: <b>{point.percentage:.0f}%</b>",
                    },
                    accessibility: {
                      point: {
                        valueSuffix: "%",
                      },
                    },
                    plotOptions: {
                      pie: {
                        allowPointSelect: true,
                        cursor: "pointer",
                        size: "90%",
                        dataLabels: {
                          enabled: false,
                        },
                        showInLegend: true,
                      },
                    },
                    series: [
                      {
                        data: chart.serie,
                      },
                    ],
                  };
                  return (
                    <Grid item xs={12} lg key={key}>
                      <Paper className={classes.paper}>
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options}
                          onChartReady={() => {
                            chart.serie.forEach(point => {
                              point.name = point.name.toUpperCase();
                            });
                          }}
                        />
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </CardContent>
        </Card>
      )}
      {error && <Alert severity="error">{error}</Alert>}
    </div>
  );
});

export default AudienceDemographic;
