import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, CircularProgress, Card, Typography } from "@material-ui/core";

import {
  ReportFilter,
  BrandPerformance,
  FastestGrowing,
  FastestDeclining,
  HighestRanking,
  LowestRanking,
  MarketPenetration,
  MdundoPlatformPerformance,
} from "./components";

import { getBrandlift } from "api/brandliftService";
import { Alert } from "@material-ui/lab";
import { Footer } from "layouts/Main/components";
import {
  AudienceDemographic,
  // AudienceMusicTaste,
  AudienceMediaConsumption,
} from "./components/Audience";
import { definitions as accordionDetails } from "data";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    width: "100%",
  },
  listContainer: {
    marginTop: 20,
  },
  loader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#4F6CFF",
  },
  link: {
    background: "yellow",
  },
}));

const Dashboard = props => {
  const classes = useStyles();

  const [filters, setFilters] = useState({
    productName: "",
    country: "",
    age_bracket: [],
    lsm: [],
    urban_rural: [],
    startDate: new Date(),
    endDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    gender: [],
    months: "",
    productCount: "",
    metrics: "",
  });

  let month;
  if (filters.months === 3) {
    month = "3 months";
  } else if (filters.months === 6) {
    month = "6 months";
  } else if (filters.months === 9) {
    month = "9 months";
  } else {
    month = "3 months";
  }

  const [brandlift, setBrandlift] = useState([]);
  const [fastestGrowing, setFastestGrowing] = useState([]);
  const [fastestDeclining, setFastestDeclining] = useState([]);
  const [highestRanking, setHighestRanking] = useState([]);
  const [lowestRanking, setLowestRanking] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [blank, setBlank] = useState("");
  const [topProducts, setTopProducts] = useState([]);

  const getReport = async () => {
    setLoading(true);
    setError("");
    setBlank("");

    try {
      const response = await getBrandlift(filters);
      setLoading(false);

      if (response.status === 200 && !response.data.insufficientData) {
        setBrandlift(response?.data.brandlift);
        setFastestGrowing(response?.data.fastestGrowing);
        setFastestDeclining(response?.data.fastestDeclining);
        setHighestRanking(response?.data.highestRanking);
        setLowestRanking(response?.data.lowestRanking);
        setTopProducts(response?.data.topNProducts);
      } else if (response.status === 500) {
        resetData();
        setBlank("No data available.");
      } else {
        setError(
          "Sorry, your search doesn’t have enough data to display. Could  you try to broaden  your filters and try again?"
        );
        resetData();
      }
    } catch (err) {
      setLoading(false);
      setError("There is a problem");
      resetData();
      setBlank("");
    }
  };

  const resetData = () => {
    setBrandlift([]);
    setFastestGrowing([]);
    setFastestDeclining([]);
    setHighestRanking([]);
    setLowestRanking([]);
    setTopProducts([]);
  };

  return (
    <div className={classes.root}>
      <ReportFilter
        filters={filters}
        setFilters={setFilters}
        getReport={getReport}
        loading={loading}
        setLoading={setLoading}
      />

      {loading ? (
        <div className={classes.loader}>
          <CircularProgress color="inherit" />
        </div>
      ) : brandlift?.length > 0 ? (
        <>
          {/* <AudienceSize className={classes.loader} filters={filters} /> */}
          <MdundoPlatformPerformance filters={filters} />
          <br />
          <BrandPerformance
            loading={loading}
            brandlift={brandlift}
            metric={filters.metrics}
            topProducts={topProducts}
            error={error}
            blank={blank}
            month={month}
          />
          <div>
            <br />
            <Card>
              <Typography variant="h5">
                Grow Brand Performance by up to 38%
                <a
                  target="_blank"
                  rel="noreferrer noopener"
                  href="http://www.mdundoforbrands.com"
                  className={classes.link}
                >
                  {" "}
                  Learn More
                </a>
              </Typography>
            </Card>
          </div>
          <Grid container spacing={4} className={classes.listContainer}>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <FastestGrowing
                products={fastestGrowing}
                loading={loading}
                error={error}
                blank={blank}
              />
            </Grid>

            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <FastestDeclining
                products={fastestDeclining}
                loading={loading}
                error={error}
                blank={blank}
              />
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <HighestRanking
                products={highestRanking}
                loading={loading}
                error={error}
                blank={blank}
              />
            </Grid>
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <LowestRanking
                products={lowestRanking}
                loading={loading}
                error={error}
                blank={blank}
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <MarketPenetration month={month} filters={filters} />
            </Grid>
            <Grid item lg={12}>
              <Card>
                <Typography variant="h5">
                  70-90% of Mdundo’s audience have not interacted with RADIO &
                  TV
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="http://www.mdundoforbrands.com"
                    className={classes.link}
                  >
                    {" "}
                    Learn More
                  </a>
                </Typography>
              </Card>
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <AudienceMediaConsumption
                className={classes.loader}
                filters={filters}
              />
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <AudienceDemographic
                className={classes.loader}
                filters={filters}
              />
            </Grid>
            <Grid item lg={12}>
              <Card>
                <Typography variant="h5">
                  Reach Mdundo’s mass-market audience
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="http://www.mdundoforbrands.com"
                    className={classes.link}
                  >
                    {" "}
                    Learn More
                  </a>
                </Typography>
              </Card>
            </Grid>
            {/* <Grid item lg={12} sm={12} xl={12} xs={12}>
              <AudienceMusicTaste
                className={classes.loader}
                filters={filters}
              />
            </Grid> */}
            <Grid item lg={12}>
              <Card>
                <Typography variant="h5">
                  Capture the attention of your audience with maximum impact
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    href="http://www.mdundoforbrands.com"
                    className={classes.link}
                  >
                    {" "}
                    Learn More
                  </a>
                </Typography>
              </Card>
            </Grid>
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <Footer data={accordionDetails} />
            </Grid>
          </Grid>
        </>
      ) : (
        ""
      )}

      {error && !loading && (
        <Alert className={classes.alert} severity="error">
          {error}
        </Alert>
      )}
      {blank && !loading && <Alert severity="info">{blank}</Alert>}
    </div>
  );
};

export default Dashboard;
