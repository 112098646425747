import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Parallax } from "react-parallax";
import Selector from "./Selector";
import About from "./About";
import Contact from "./Contact";
import Partners from "./Partners";
import Awards from "./Awards";

import { categories, countries } from "../../data";

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  topContainer: {
    textAlign: "center",
    paddingLeft: 20,
    paddingRight: 20,
  },
  aboutContainer: {
    backgroundColor: "rgb(250 250 250)",
  },
  awardsContainer: {
    backgroundColor: "rgb(250 250 250)",
  },
  partnerContainer: {},
  insideStyles: {
    fontSize: 20,
    paddingBottom: 50,
  },
  top: {
    paddingTop: 100,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 200,
    },
  },
}));

const Home = () => {
  const classes = useStyles();

  const [filters, setFilters] = React.useState({
    country: "",
    category: "",
  });

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div id="top" className={classes.topContainer}>
          <Parallax strength={700} blur={10} className={classes.top}>
            <div style={{ height: 500 }}>
              <div className={classes.insideStyles}>
                EXPLORE WHAT THE MDUNDO AUDIENCE THINKS ABOUT YOUR BRAND.
              </div>
              <div>
                <Selector
                  categories={categories}
                  countries={countries}
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
            </div>
          </Parallax>
        </div>
        <div className={classes.aboutContainer}>
          <About />
        </div>
        <div className={classes.partnerContainer}>
          <Partners />
        </div>
        <div className={classes.awardsContainer}>
          <Awards />
        </div>
        <div className={classes.contactContainer}>
          <Contact />
        </div>
      </div>
    </div>
  );
};

export default Home;
