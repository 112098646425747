import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { useGet } from "hooks/useGet";
import React from "react";

const useStyles = makeStyles((theme) => ({
  list: {
    paddingLeft: "1rem",
  },
}));

function AudienceMusicTaste({ filters, className }) {
  const classes = useStyles();
  const { data, loading, error } = useGet(filters, "ga/audience/music");
  const reloadRef = React.useRef();
  // reloading page on every change
  React.useEffect(() => {
    reloadRef.current = true;
  }, [filters]);
  
  return (
    <>
      {loading ? (
        <div className={className}>
          <CircularProgress disableShrink />
        </div>
      ) : (
        <Card>
          <CardHeader title='Music Taste (%) within the Mdundo Audience, last 7 days' />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <TableContainer style={{ height: "100%" }} width='100%' component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell width='100%'>Top Artist</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.artists.map((artist, index) => (
                        <TableRow key={index}>
                          <TableCell width='100%'>
                            <ul className={classes.list}>
                              <p>
                                {index + 1}. {artist}
                              </p>
                            </ul>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <TableContainer width='100%' component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Top Song</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.songs.map((song, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <ul className={classes.list}>
                              <p>
                                {index + 1}. {song}
                              </p>
                            </ul>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item lg={4} sm={12} xl={4} xs={12}>
                <TableContainer style={{ height: "100%" }} component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Top Genre</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.genres.map((genre, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            <ul className={classes.list}>
                              <p>
                                {index + 1}. {genre}
                              </p>
                            </ul>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}
      {error && !loading && <Alert severity='error'>{error}</Alert>}
    </>
  );
}

export default AudienceMusicTaste;
