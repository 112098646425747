import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography, Grid } from "@material-ui/core";
import { Parallax } from "react-parallax";
import { Element } from "react-scroll";

const useStyles = makeStyles(theme => ({
  insideStyles: {
    fontSize: 20,
    paddingBottom: 50,
  },
  about: {
    paddingTop: 50,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 200,
      paddingLeft: 200,
      paddingRight: 200,
      paddingBottom: 200,
    },
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    fontSize: 16,
    marginBottom: 18,
  },
  subtitle: {
    marginTop: 32,
  },
  imgContainer: {
    heigth: 300,
    overflow: "hidden",
  },
  img: {
    width: "100%",
    heigth: "auto",
    aspectRatio: "16/9",
  },
}));

const About = () => {
  const classes = useStyles();

  return (
    <Element id="about" name="about">
      <Parallax strength={700} blur={10} className={classes.about}>
        <div>
          <div className={classes.insideStyles}>
            <Grid container spacing={2}>
              <Grid item lg={8} sm={6} xl={8} xs={12}>
                <Typography gutterBottom variant="h3">
                  ABOUT MDUNDO
                </Typography>
                <Typography gutterBottom variant="h5">
                  MDUNDO FOR FANS
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.text}
                >
                  Mdundo is Africa's leading mobile-web based music service. We
                  offer millions of songs from African and abroad for free
                  downloads and streaming directly from our website and android
                  app including the Newest Releases, DJ Mixes, Playlists,
                  Podcasts and Music News.
                </Typography>
                <Typography
                  gutterBottom
                  variant="h5"
                  className={classes.subtitle}
                >
                  MDUNDO FOR ARTISTS
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.text}
                >
                  We work with more than 60,000 artists from across Africa as
                  well as some of the world's leading record labels. Our artist
                  services are focused on flexibility, transparency and fair
                  compensation. All revenues are equally shared with the content
                  owners. Join Mdundo as an artist today!
                </Typography>

                <Typography
                  gutterBottom
                  variant="h5"
                  className={classes.subtitle}
                >
                  MDUNDO FOR FANS
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.text}
                >
                  People love music! That's why we believe that music is the
                  most powerful way of connecting with people on an emotional
                  level.
                </Typography>
                <Typography
                  gutterBottom
                  variant="body1"
                  className={classes.text}
                >
                  We offer a unique and culturally relevant approach to brand
                  marketing. Whether your brand is looking to shift perception,
                  reach a new generation, boost sales or build loyalty, we will
                  help you make your mark on the cultural landscape in an
                  authentic and meaningful way.
                </Typography>
              </Grid>
              <Grid item lg={4} sm={6} xl={4} xs={12}>
                <div className={classes.imgContainer}>
                  <img
                    src="/images/trial.jpg"
                    className={classes.img}
                    alt="Trial"
                  />
                </div>
                <div className={classes.imgContainer}>
                  <img
                    src="/images/trial.jpg"
                    className={classes.img}
                    alt="Trial"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Parallax>
    </Element>
  );
};

export default About;
