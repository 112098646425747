import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Tooltip,
  Typography,
} from "@material-ui/core";

import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";

import { data } from "./chart";
import { getMarketPenetration } from "api/marketPenetration";
import { categories } from "data";
import { Alert, Skeleton } from "@material-ui/lab";
import { HelpOutlineRounded } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    position: "relative",
  },
  actions: {
    justifyContent: "flex-end",
  },
  loader: {
    alignItems: "center",
    justifyContent: "center",
    color: "red",
  },
}));

const MarketPenetration = React.forwardRef((props, ref) => {
  const { load, filters, month, ...rest } = props;
  const mpChart = React.useRef();
  const classes = useStyles();
  const [labels, setLabels] = React.useState([]);
  const [series, setSeries] = React.useState([]);
  const [marketPenetration, setMarketPenetration] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [blank, setBlank] = React.useState("");
  const verbs = categories.map(i => i.subcategories);

  React.useEffect(() => {
    if (marketPenetration.length > 0) {
      const summary = data(marketPenetration, filters.productName);
      setLabels(summary.labels);
      setSeries(summary.datasets);
    }
  }, [marketPenetration, filters.productName]);

  const fetchMarketPenetration = async () => {
    setLoading(false);
    try {
      const response = await getMarketPenetration(filters, verbs);
      if (response.status === 200 && !response.data.insufficientData) {
        setMarketPenetration(response.data.marketPenetration);
      } else if (response.status === 500) {
        setBlank("No data available");
        resetData();
      } else {
        setError(
          "Sorry, your search doesn’t have enough data to display. Could  you try to broaden  your filters and try again?"
        );
        resetData();
      }
    } catch (err) {
      setLoading(false);
      setError("There is a problem");
      resetData();
      setBlank("");
    }
  };
  React.useEffect(() => {
    fetchMarketPenetration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const resetData = () => {
    setMarketPenetration([]);
  };

  const options = {
    title: {
      text:
        filters.productName.charAt(0).toUpperCase() +
        filters?.productName?.replace(/_/gi, " ").slice(1),
      categories: labels,
    },
    chart: { type: "spline" },
    credits: {
      enabled: false,
    },
    yAxis: {
      alternateGridColor: "#f8f8f8",
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      title: {
        text: "MarketPenetration %",
      },
    },
    plotOptions: {
      spline: {
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        marker: {
          enabled: false,
        },
      },
    },
    xAxis: {
      text: "",
      categories: labels,
      gridLines: { display: false },
    },
    legend: {
      enabled: false,
    },
    series: series,

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  };
  return (
    <Card {...rest} className={classes.root}>
      <CardHeader
        title={
          <>
            <Typography
              style={{
                fontWeight: "bold",
                display: "inline-flex",
                alignItems: "center",
              }}
              gutterBottom
            >
              Market Penetration (%) within the Mdundo Audience, last {month}
              <Tooltip
                title="The percentage of Mdundo users that have used a product in the category in the last 90days"
                placement="bottom-start"
                arrow
                style={{
                  marginLeft: "0.5rem",
                }}
              >
                <HelpOutlineRounded fontSize="small" color="secondary" />
              </Tooltip>
            </Typography>
          </>
        }
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          {loading ? (
            <Skeleton variant="rect" width={"100%"} height={300} />
          ) : (
            <div ref={ref} id="highcharts-container">
              <HighchartsReact
                ref={mpChart}
                highcharts={Highcharts}
                options={options}
              />
            </div>
          )}
        </div>
      </CardContent>
      {error && !loading && (
        <Alert className={classes.alert} severity="error">
          {error}
        </Alert>
      )}
      {blank && !loading && <Alert severity="info">{blank}</Alert>}
    </Card>
  );
});

MarketPenetration.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.string,
  blank: PropTypes.string,
};

export default MarketPenetration;
