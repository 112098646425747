/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { Link, NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  List,
  ListItem,
  Button,
  colors,
  Divider,
  Box,
} from "@material-ui/core";
import LogoutIcon from "@mui/icons-material/Logout";

const useStyles = makeStyles((theme) => ({
  root: { height: "100%", position: "relative" },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    "& a": {
      display: "flex",
    },
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
  logout: {
    position: "absolute",
    bottom: 10,
    left: 0,
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNav = (props) => {
  const { pages, className, handleSubmit, ...rest } = props;

  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      <List>
        {pages.map((page) => (
          <ListItem className={classes.item} disableGutters key={page.title}>
            <Button
              // activeClassName={classes.active}
              className={classes.button}
              component={CustomRouterLink}
              to={page.href}
            >
              <div className={classes.icon}>{page.icon}</div>
              {page.title}
            </Button>
          </ListItem>
        ))}
      </List>

      <Divider />
      {localStorage.getItem("user") ? (
        <Button
          className={clsx(classes.button, classes.logout)}
          variant='contained'
          onClick={handleSubmit}
        >
          <Link to='/'>
            <div className={classes.icon}>
              <LogoutIcon />
            </div>
            Logout
          </Link>
        </Button>
      ) : null}
    </Box>
  );
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNav;
