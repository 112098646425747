import axios from "axios";
import { apiUrl } from "./api";

export const getAudience = async (values, endpoint) => {
  const params = {};

  if (values.country) {
    params.country = '"' + values.country + '"';
  }
  if (values.age_bracket.length) {
    params.age_bracket = getParams(values.age_bracket);
  }
  if (values.lsm.length) {
    params.lsm = getParams(values.lsm.map((v) => v.key));
  }
  if (values.urban_rural.length) {
    params.urban_rural = getParams(values.urban_rural);
  }
  if (values.gender.length) {
    params.gender = getParams(values.gender.map((v) => v.key));
  }

  try {
    return await axios.get(`${apiUrl.baseUrl}/${endpoint}`, {
      params,
    });
  } catch (err) {
    return err.response;
  }
};

const getParams = (values) => values.map((v) => JSON.stringify(v)).join(",");
