const getLabels = (marketPenetration, productName) => {
  let labelSet = new Set(); // hold the date labels
  const marketsMap = new Map(); // hold marketPenetration data for all markets

  const dateMap = new Map(); // hold data for each date
  marketPenetration.forEach((element) => {
    // set label dates
    labelSet.add(element.date);
    // set data for each markets
    if (dateMap.has(element.date)) {
      const current = dateMap.get(element.date);
      dateMap.set(element.date, [...current, { ...element }]);
    } else {
      dateMap.set(element.date, [{ ...element }]);
    }
    // set marketPenetration data for all markets
    if (marketsMap.has(element.product_service)) {
      const current = marketsMap.get(element.product_service);
      marketsMap.set(element.product_service, [
        ...current,
        element.marketPenetration,
      ]);
    } else {
      marketsMap.set(element.product_service, [element.marketPenetration]);
    }
  });

  const labels = [...labelSet];
  // generate datasets
  let datasets = [];
  const data = [...marketsMap.get()];
  datasets.push({
    name: productName?.replace(/_/gi, " "),
    data: data.map((v) => Math.round(v * 100)),
  });

  return {
    labels,
    datasets,
  };
};

export const data = (marketPenetration, metric) => {
  const summary = getLabels(marketPenetration, metric);
  return {
    labels: summary.labels,
    datasets: summary.datasets,
  };
};
