import { createTheme } from "@material-ui/core";

import typography from "./typography";
import overrides from "./overrides";

const theme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
      contrastText: "black",
      loader: "#4F6CFF",
    },
    secondary: {
      main: "#4F6CFF",
    },
  },
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
