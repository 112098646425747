import { Card, CardContent, CardHeader } from "@material-ui/core";
import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Skeleton } from "@material-ui/lab";

function AdsPlay(props) {
  const { campaign, labels } = props;

  let series = {};

  series = [
    {
      name: "Ad Plays",
      data: campaign.map((i) => i.data),
    },
  ];
  const options = {
    title: {
      text: null,
    },
    yAxis: {
      title: {
        text: "Ad Plays",
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    xAxis: {
      tickmarkPlacement: "on",
      tickLength: 10,
      categories: [...labels],
      labels: { rotation: -45, align: "right" },
    },
    series: series,
  };

  return (
    <>
      <Card>
        <CardHeader title='Ad Plays By Day' />
        <CardContent>
          {campaign.length === 0 ? (
            <Skeleton variant='rect' height={400} width={"100%"} />
          ) : (
            <HighchartsReact highcharts={Highcharts} options={options} />
          )}
        </CardContent>
      </Card>
    </>
  );
}

export default AdsPlay;
