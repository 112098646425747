import {
  Card,
  CardContent,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { HelpOutlineRounded } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import useBrand from "hooks/useBrand";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    "&>span": {
      backgroundColor: "yellow",
    },
  },
}));

function MdundoPlatformPerformance({ filters }) {
  const { oneYearAvgPerfData, data } = useBrand(filters);
  const cls = useStyles();

  let skeleton;

  if (oneYearAvgPerfData.length === 0) {
    skeleton = <Skeleton variant='rect' height={100} width={"100%"} />;
  }

  return (
    <div>
      <Paper variant='outlined' style={{ padding: "1rem" }}>
        <Typography variant='h3' gutterBottom>
          Mdundo Platform Performance
        </Typography>
        <Typography align='left' variant='h4' gutterBottom className={cls.root}>
          The target audience includes <span>{data.unique_users}</span> unique
          users served a total of <span>{data.audio_ads}</span> audio ads in the
          last 90 days. The average campaign performance on Mdundo is:
        </Typography>
        <br />
        {oneYearAvgPerfData.map((item, index) => (
          <Grid container spacing={2} key={index}>
            {item.tg.map((i) => (
              <Grid item lg xs={12} key={i.name}>
                <Card variant='outlined' style={{ minWidth: 200 }}>
                  <CardContent key={item.title}>
                    <Typography
                      style={{
                        display: "inline-flex",
                        alignItems: "center",
                      }}
                      gutterBottom
                    >
                      <strong>{i.name}</strong>{" "}
                      {i.name ? (
                        <Tooltip
                          title={i.definition}
                          placement='bottom-start'
                          arrow
                          style={{
                            marginLeft: "0.5rem",
                          }}
                        >
                          <HelpOutlineRounded
                            fontSize='small'
                            color='secondary'
                          />
                        </Tooltip>
                      ) : null}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "30px",
                        paddingTop: ".5rem",
                      }}
                      color='textSecondary'
                    >
                      {i.data.percentage}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        ))}
        {skeleton}
      </Paper>
    </div>
  );
}

export default MdundoPlatformPerformance;
