import { Card, CardContent, CardHeader } from "@material-ui/core";
import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { Alert } from "@material-ui/lab";

const BrandPerformance = React.forwardRef((props, ref) => {
  const { brandPerformance } = props;

  let series = {};

  function toPercentage(val) {
    return Math.round(val * 100);
  }
  series = [
    {
      name: "Brand Lift",
      data: brandPerformance?.brandPerformance?.map(i =>
        toPercentage(i?.brandlift)
      ),
    },
    {
      name: "Awareness",
      data: brandPerformance?.brandPerformance?.map(i =>
        toPercentage(i?.awareness)
      ),
    },
    {
      name: "Consideration",
      data: brandPerformance?.brandPerformance?.map(i =>
        toPercentage(i?.consideration)
      ),
    },
    {
      name: "Favourability",
      data: brandPerformance?.brandPerformance?.map(i =>
        toPercentage(i?.favourability)
      ),
    },
    {
      name: "Purchase Intent",
      data: brandPerformance?.brandPerformance?.map(i =>
        toPercentage(i?.intent)
      ),
    },
  ];
  // }

  const options = {
    title: {
      text: null,
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    yAxis: {
      labels: { enabled: true },
      title: {
        text: "Brand Performance",
      },
    },
    xAxis: {
      tickmarkPlacement: "on",
      tickLength: 10,
      categories: brandPerformance?.brandPerformance?.map(i => i?.date),
      labels: { rotation: -45, align: "right" },
    },
    series: series,
    credits: false,
  };

  return (
    <>
      <Card>
        <CardHeader title="Brand Performance" />
        <CardContent>
          <>
            {!brandPerformance ? (
              <Alert style={{ maxWidth: "240px" }} severity="warning">
                Not Enough data!
              </Alert>
            ) : (
              <div ref={ref}>
                <HighchartsReact
                  // ref={brandPerformanceRef}
                  highcharts={Highcharts}
                  options={options}
                />
              </div>
            )}
          </>
        </CardContent>
      </Card>
    </>
  );
});

export default BrandPerformance;
