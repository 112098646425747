import ReactGA from "react-ga";

export const PageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

/**
 * Event - custom tracking event.
 * @param {string} category - The event category.
 * @param {string} action - The event action.
 * @param {string} label - The event label.
 */

export const eventTracker = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const initGA = (trackingID) => {
  ReactGA.initialize(trackingID);
};
