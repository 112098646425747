import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Parallax } from "react-parallax";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Element } from "react-scroll";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {},
  insideStyles: {
    fontSize: 20,
    paddingBottom: 50,
  },
  partner: {
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 200,
      paddingLeft: 200,
      paddingRight: 200,
      paddingBottom: 200,
    },
    paddingLeft: 20,
    paddingRight: 20,
  },
}));

const Partners = () => {
  const classes = useStyles();
  const matches = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const tileData = [
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
    {
      img: "/images/trial.jpg",
      title: "Image",
    },
  ];

  return (
    <Element id="contact" name="contact">
      <Parallax strength={700} blur={10} className={classes.partner}>
        <div className={classes.insideStyles}>
          <Typography gutterBottom variant="h3">
            MDUNDO PARTNERS
          </Typography>
          <div className={classes.root}>
            <GridList className={classes.gridList} cols={matches ? 5 : 1}>
              {tileData.map((tile, key) => (
                <GridListTile key={tile.img + key} cols={1}>
                  <img src={tile.img} alt={tile.title} />
                </GridListTile>
              ))}
            </GridList>
          </div>
        </div>
      </Parallax>
    </Element>
  );
};

export default Partners;
