import React from "react";
import { Redirect, Route } from "react-router-dom";

const RouteWithLogin = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("user") ? (
            <Layout>
              <Component {...props} />
            </Layout>
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
      <div></div>
    </>
  );
};

export default RouteWithLogin;
