/* eslint-disable no-unused-vars */
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  CircularProgress,
  Fade,
  Grid,
  ImageList,
  ImageListItem,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import useGetReq from "hooks/useGetReq";
import moment from "moment";
import { Audience, BrandPerformance, CampaignPerformance } from "./components";
import { apiUrl } from "api/api";
import { Footer } from "layouts/Main/components";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import CocaCola from "../assets/logos/Brandlift/CocaCola.png";
import Dentsu from "../assets/logos/Brandlift/Dentsu.png";
import DigitXPlus from "../assets/logos/Brandlift/digitXplus.png";
import Eabl from "../assets/logos/Brandlift/eabl.jpg";
import Guiness from "../assets/logos/Brandlift/Guinness.png";
import Iprospect from "../assets/logos/Brandlift/iProspect.jpg";
import Nivea from "../assets/logos/Brandlift/Nivea.png";
import Phd from "../assets/logos/Brandlift/phd.png";
import Safaricom from "../assets/logos/Brandlift/safaricom.png";
import Saracen from "../assets/logos/Brandlift/Saracen.png";
import Smart from "../assets/logos/Brandlift/Smart.jpg";
import StandardChartered from "../assets/logos/Brandlift/Standard_Chartered.png";
import Tusker from "../assets/logos/Brandlift/Tusker.jpg";
import VodacomWine from "../assets/logos/Brandlift/Vodacom_wine.png";
import Vodacom from "../assets/logos/Brandlift/Vodacom.png";
import { Element } from "react-scroll";
import { Parallax } from "react-parallax";
import { Button } from "@mui/material";

import { createPDF } from "template/Reporting/createPdf";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: "100%",
  },
  error: {
    width: "100%",
    height: "90vh",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  partner: {},
}));

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   // width: 400,
//   background: "#DCE1E9",
//   border: "2px solid #000",
//   boxShadow: 24,
//   color: "#f8f8f8",
//   p: 4,
// };

function Reporting(props) {
  const classes = useStyles();
  const [brand, setBrand] = React.useState({});
  const [brandPerformance, setBrandPerformance] = React.useState();
  const { getData, cancelRequests, loading, error, data } = useGetReq();
  const matches = useMediaQuery(theme => theme.breakpoints.up("sm"));
  const printRef = React.useRef();

  const campaignPerformanceRef = React.useRef();
  const brandPerformanceRef = React.useRef();
  const audienceMediaConsumptionRef = React.useRef();
  const audienceDemographicRef = React.useRef();
  const marketPenetrationRef = React.useRef();

  React.useEffect(() => {
    if (localStorage.getItem("user")) {
      let productId = window
        .atob(localStorage.getItem("user")?.replace(/['"]+/g, ""))
        .split(":");

      getData(`${apiUrl.baseUrl}/client/brandlift/${productId[0]}`);
      getData(`${apiUrl.baseUrl}/client/brandlift-aggregates/${productId[0]}`);
    }

    return () => cancelRequests;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, loading]);

  let campaign = [];
  let campaignData = [];
  let thirtyDaysAvgPerfData = [];
  let oneYearAvgPerfData = [];
  let labels = new Set();

  if (brand) {
    Object.entries(brand).forEach(k => {
      if (k?.find(el => el === "adsPlay")) {
        for (let value of k.pop()) {
          const gt = {};
          gt.name = value.promo_name;
          labels.add(moment(value.promo_date).format("DD-MM-YYYY"));
          gt.data = value.ads_play;
          gt.adsPlay = value.ads_play;
          campaign.push(gt);
        }
      } else if (k?.find(el => el === "campaign")) {
        campaignData.push(k.pop());
      } else if (k?.find(el => el === "thirtyDaysAvgPerf")) {
        var i;
        for (i = 0; i < k?.length; i++) {
          const gt = {};
          let kt = [k.pop()];

          const brandScore =
            brandPerformance?.brandPerformance[
              brandPerformance?.brandPerformance.length - 1
            ];

          const formatPercent = num => {
            return Math.round(num * 100);
          };
          gt.tg = [
            {
              name: "Brand Lift",
              data: kt[0].brandlift,
              brandScore: formatPercent(brandScore?.brandlift),
              definition: `The overall performance of a brand within the Mdundo Audience including awareness, favourability, consideration and purchase intent.`,
            },
            {
              name: "Awareness",
              data: kt[0].awareness,
              brandScore: formatPercent(brandScore?.awareness),
              definition: `Measures if the Mdundo audience is aware of the brand relative to competing brands.`,
            },
            {
              name: "Consideration",
              data: kt[0].consideration,
              brandScore: formatPercent(brandScore?.consideration),
              definition: `Measures if the Mdundo audience would consider purchasing the brand relative to competing brands. `,
            },
            {
              name: "Favourability",
              data: kt[0].favourability,
              brandScore: formatPercent(brandScore?.favourability),
              definition: `Measures if the Mdundo audience favour the brand relative to competing brands.`,
            },
            {
              name: "Purchase Intent",
              data: kt[0].intent,
              brandScore: formatPercent(brandScore?.intent),
              definition: `Measures if the Mdundo audience would purchase the brand at the next point of purchase.`,
            },
          ];

          thirtyDaysAvgPerfData.push(gt);
        }
      } else if (k.find(el => el === "oneYearAvgPerf")) {
        // eslint-disable-next-line no-redeclare
        var i;
        for (i = 0; i < k.length; i++) {
          const gt = {};
          let kt = [k.pop()];

          gt.tg = [
            {
              name: "Brand Lift",
              data: kt[0]?.brandlift,
              definition: `The overall performance of a brand
            within the Mdundo Audience including awareness, favourability,
            consideration and purchase intent.`,
            },
            {
              name: "Awareness",
              data: kt[0]?.awareness,
              definition: `Measures if the Mdundo audience is
            aware of the brand relative to competing brands. Research
            Questions: Which of the following brand names have you heard of?`,
            },
            {
              name: "Consideration",
              data: kt[0]?.consideration,
              definition: `Consideration measures if the
            Mdundo audience would consider purchasing the brand relative to
            competing brands. Research question: Which of these cooking oils
            would you consider purchasing?`,
            },
            {
              name: "Favourability",
              data: kt[0]?.favourability,
              definition: `Favourability measures if the Mdundo
            audience favour the brand relative to competing brands. Research
            Question:Which of the following cooking oils do you have a
            positive opinion of?`,
            },
            {
              name: "Purchase Intent",
              data: kt[0]?.intent,
              definition: `Purchase intent measures if the
            Mdundo audience will purchase the brand at the next point of
            purchase. Research Question: Next time you buy cooking oil, which
            of the following are you likely to buy?`,
            },
          ];
          oneYearAvgPerfData.push(gt);
        }
      }
    });
  }

  if (loading && error) {
    return (
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "90vh",
          flexDirection: "column",
        }}
      >
        <CircularProgress color="secondary" />
        <Typography component="code">Loading, please wait...</Typography>
      </Box>
    );
  }

  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
  };

  return (
    <>
      {data?.insufficientData && (
        <Snackbar
          style={{ width: "360px" }}
          TransitionComponent={Fade}
          autoHideDuration={6000}
          open={data?.insufficientData}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert severity="warning" style={{ width: "100%" }}>
            Insufficient data
          </Alert>
        </Snackbar>
      )}
      <br />
      <Box style={{ padding: "2rem" }}>
        <Grid justifyContent="flex-end" container columns={16}>
          <Grid item>
            <Button
              variant="contained"
              endIcon={<PictureAsPdfIcon />}
              onClick={() =>
                createPDF([
                  campaignPerformanceRef.current,
                  brandPerformanceRef.current,
                  marketPenetrationRef.current,
                  audienceDemographicRef.current,
                  audienceMediaConsumptionRef.current,
                ])
              }
            >
              Export PDF
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box style={{ padding: "2rem" }}>
        <CampaignPerformance
          ref={campaignPerformanceRef}
          brand={data}
          classes={classes}
        />
      </Box>
      <Box style={{ padding: "2rem" }}>
        <BrandPerformance
          brandPerformanceRef={brandPerformanceRef}
          brandPerformance={data}
          ref={brandPerformanceRef}
        />
        <br />
        <Audience
          ref={{
            audienceMediaConsumptionRef,
            marketPenetrationRef,
            audienceDemographicRef,
          }}
          campaign={data}
        />
      </Box>

      <Box style={{ padding: "2rem" }}>
        <Element id="contact" name="contact">
          <Parallax strength={700} blur={10} className={classes.partner}>
            <div>
              <Typography gutterBottom variant="h3">
                WE HAVE WORKED WITH
              </Typography>
              <div>
                <ImageList
                  style={{
                    padding: "0 100px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  cols={matches ? 6 : 1}
                >
                  {[
                    { src: CocaCola },
                    { src: StandardChartered },
                    { src: VodacomWine },
                    { src: Safaricom },
                    { src: Phd },
                    { src: Saracen },
                    { src: DigitXPlus },
                    { src: Guiness },
                    { src: Dentsu },
                    { src: Eabl },
                    { src: Iprospect },
                    { src: Smart },
                    { src: Tusker },
                    { src: Nivea },
                    { src: Vodacom },
                  ].map((tile, key) => (
                    <ImageListItem
                      key={key}
                      cols={1}
                      style={{
                        width: "140px",
                        maxHeight: "130px",
                        height: "fit-content",
                        display: "flex",
                        alignItems: "flex-end",
                        padding: "1rem",
                      }}
                    >
                      <img
                        style={{
                          alignSelf: "center",
                          width: "100%",
                          height: "auto",
                          padding: "10px",
                        }}
                        src={tile.src}
                        alt={tile.src}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </div>
            </div>
          </Parallax>
        </Element>
      </Box>
      <Footer />
    </>
  );
}

export default Reporting;
