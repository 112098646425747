import React from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import {
  TrendingUp,
  VolumeUpOutlined,
} from "@material-ui/icons";
import { commaSeparator } from "utils/commaSeparator";
import { Alert } from "@material-ui/lab";
import useBrand from "hooks/useBrand";
import { formatPercent, getPercentage } from "utils/functions/numbers";

const CampaignPerformance = React.forwardRef((props, ref) => {
  const { classes, brand } = props;

  const { data } = useBrand();

  const totalAdsPlay = brand?.adsPlay
    ?.map(i => Number(i?.ads_play))
    .reduce(function add(a, b) {
      return a + b;
    }, 0);

  // eslint-disable-next-line no-unused-vars
  let skeleton;

  var a = data?.audio_ads;
  a = a?.replace(/,/g, "");

  const cData = [
    {
      value: brand?.campaign?.promo_start_date
        ? moment(brand?.campaign?.promo_start_date)?.format("DD-MM-YYYY")
        : "--",
      label: "Start Date",
    },
    {
      value: brand?.campaign?.country ?? "--",

      label: "Country",
    },
    {
      value: brand?.campaign?.client_name ?? "--",
      label: "Client Name",
    },
    {
      value: brand?.campaign?.promo_name ?? "--",
      label: "Campaign Name",
    },
  ];
  let score;

  if (data?.brandPerformance > 0) {
    score = data?.brandPerformance[data?.brandPerformance?.length - 1];
  }

  const thirtyDaysAvgPerfData = [
    {
      name: "Brand Lift",
      data: brand?.thirtyDaysAvgPerf?.brandlift,
      brandScore: formatPercent(score?.brandlift),
      definition: `The overall performance of a brand within the Mdundo Audience including awareness, favourability, consideration and purchase intent.`,
    },
    {
      name: "Awareness",
      data: brand?.thirtyDaysAvgPerf?.awareness,
      brandScore: formatPercent(score?.awareness),
      definition: `Measures if the Mdundo audience is aware of the brand relative to competing brands.`,
    },
    {
      name: "Consideration",
      data: brand?.thirtyDaysAvgPerf?.consideration,
      brandScore: formatPercent(score?.consideration),
      definition: `Measures if the Mdundo audience would consider purchasing the brand relative to competing brands. `,
    },
    {
      name: "Favourability",
      data: brand?.thirtyDaysAvgPerf?.favourability,
      brandScore: formatPercent(score?.favourability),
      definition: `Measures if the Mdundo audience favour the brand relative to competing brands.`,
    },
    {
      name: "Purchase Intent",
      data: brand?.thirtyDaysAvgPerf?.intent,
      brandScore: formatPercent(score?.intent),
      definition: `Measures if the Mdundo audience would purchase the brand at the next point of purchase.`,
    },
  ];

  return (
    <div ref={ref}>
      <Box className={classes.root}>
        <Paper variant="outlined" style={{ padding: "1rem" }}>
          <Typography variant="h3" gutterBottom>
            Your Campaign Performance
          </Typography>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid item lg={5} xs>
              <Card variant="outlined">
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <CardContent>
                    <IconButton aria-label="play/pause">
                      <a
                        alt="audio-link"
                        href={brand?.campaign?.audio_link ?? "--"}
                      >
                        <VolumeUpOutlined
                          style={{ height: "38px", width: "38px" }}
                        />
                      </a>
                    </IconButton>
                  </CardContent>
                  <CardContent>
                    <Typography component="div" variant="h6">
                      Audio ad script:
                    </Typography>
                    <code>{brand?.campaign?.audio_script ?? "--"}</code>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={5} style={{ padding: "1rem" }}>
              <Grid
                container
                spacing={2}
                style={{
                  height: "100%",
                }}
              >
                <Grid item lg>
                  <Card variant="outlined" style={{ height: "100%" }}>
                    <CardContent
                      style={{
                        margin: 0,
                        padding: ".5rem .5rem 0 .5rem",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                        gutterBottom
                      >
                        Total Ad-Plays{" "}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "26px",
                          paddingTop: ".5rem",
                          lineHeight: "100%",
                        }}
                        color="textSecondary"
                        gutterBottom
                      >
                        {totalAdsPlay ? commaSeparator(totalAdsPlay) : "--"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Typography
                        style={{
                          fontSize: "13px",
                          paddingTop: ".5rem",
                          lineHeight: "100%",
                        }}
                        color="textSecondary"
                        gutterBottom
                      >
                        Total Ads your campaign received
                      </Typography>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item lg>
                  <Card variant="outlined">
                    <CardContent
                      style={{
                        margin: 0,
                        padding: ".5rem .5rem 0 .5rem",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                        gutterBottom
                      >
                        Campaign Share of voice
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "26px",
                          paddingTop: ".5rem",
                          lineHeight: "100%",
                        }}
                        color="textSecondary"
                        gutterBottom
                      >
                        {totalAdsPlay ? getPercentage(totalAdsPlay, a) : "--"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Typography
                        style={{
                          fontSize: "13px",
                          paddingTop: ".5rem",
                          lineHeight: "100%",
                        }}
                        color="textSecondary"
                        gutterBottom
                      >
                        The percentage of your brand's ad plays as compared to
                        the total ads served during the period
                      </Typography>
                    </CardActions>
                  </Card>
                </Grid>
                <Grid item lg>
                  <Card variant="outlined">
                    <CardContent
                      style={{
                        margin: 0,
                        padding: ".5rem .5rem 0 .5rem",
                      }}
                    >
                      <Typography
                        style={{
                          fontWeight: "bold",
                          display: "inline-flex",
                          alignItems: "center",
                        }}
                        gutterBottom
                      >
                        Campaign Reach
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "26px",
                          paddingTop: ".5rem",
                          lineHeight: "100%",
                        }}
                        color="textSecondary"
                        gutterBottom
                      >
                        {totalAdsPlay
                          ? commaSeparator(
                              Math.floor(
                                (parseInt(totalAdsPlay) /
                                  parseInt(data?.audio_ads)) *
                                  parseInt(data?.unique_users)
                              )
                            )
                          : "--"}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Typography
                        style={{
                          fontSize: "13px",
                          paddingTop: ".5rem",
                          lineHeight: "100%",
                        }}
                        color="textSecondary"
                        gutterBottom
                      >
                        Number of unique users that have been exposed to the ad
                      </Typography>
                    </CardActions>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            {cData.map((it, index) => (
              <Grid item lg xs={12} key={index}>
                <Card
                  variant="outlined"
                  style={{ display: "flex", height: "100%" }}
                >
                  <CardContent
                    style={{
                      margin: 0,
                      padding: ".5rem .5rem 0 .5rem",
                    }}
                  >
                    <Typography style={{ fontWeight: "bold" }} gutterBottom>
                      {it.label}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "26px",
                        paddingTop: ".5rem",
                        lineHeight: "100%",
                      }}
                      color="textSecondary"
                      gutterBottom
                    >
                      {it.value}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>

          <br />
          <Grid container spacing={2}>
            {thirtyDaysAvgPerfData?.map(item => {
              if (item?.data?.customers >= 0) {
                return (
                  <Grid
                    key={item.name}
                    item
                    lg={thirtyDaysAvgPerfData?.length >= 4 ? 3 : true}
                  >
                    <Card variant="outlined" style={{ height: "100%" }}>
                      <CardContent>
                        <Typography
                          gutterBottom
                          style={{
                            fontWeight: "bold",
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          {item.name}
                        </Typography>
                        {!item?.data?.proportion &&
                        !item?.data?.customers &&
                        item?.data?.percentage === "NaN%" ? (
                          <Alert
                            severity="warning"
                            style={{
                              width: "fit-content",
                              paddingTop: ".4rem",
                            }}
                          >
                            Not enough data to display
                          </Alert>
                        ) : (
                          <>
                            <Typography
                              style={{
                                fontSize: "20px",
                                padding: ".5rem 0",
                              }}
                              color="textSecondary"
                            >
                              {parseInt(item?.data?.percentage) >= 0 ? (
                                <>
                                  {Math.round(item?.data?.proportion * 100) +
                                    "%"}{" "}
                                  <span
                                    style={{
                                      color: "green",
                                    }}
                                  >
                                    <TrendingUp fontSize="small" />
                                  </span>
                                </>
                              ) : null}
                            </Typography>
                            {parseInt(item?.data?.customers) > 0 && (
                              <Typography
                                variant="body1"
                                component="code"
                                style={{ fontWeight: "bold" }}
                              >
                                {item.data.customers
                                  ? commaSeparator(item?.data?.customers ?? 0)
                                  : item.data.customers ?? 0}{" "}
                                <span
                                  style={{
                                    fontWeight: "lighter",
                                  }}
                                >
                                  customers
                                </span>
                                <br />
                                <Typography style={{ color: "gray" }}>
                                  {item.definition}
                                </Typography>
                              </Typography>
                            )}
                          </>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              }
              // eslint-disable-next-line array-callback-return
              return;
            })}
          </Grid>
        </Paper>
      </Box>
    </div>
  );
});

export default CampaignPerformance;
