import { Box } from "@material-ui/core";
import React from "react";
import { MarketPenetration } from "views/Dashboard/components";
import {
  AudienceDemographic,
  AudienceMediaConsumption,
  // AudienceMusicTaste,
} from "views/Dashboard/components/Audience";

const Audience = React.forwardRef(({ campaign }, ref) => {
  const {
    marketPenetrationRef,
    audienceMediaConsumptionRef,
    audienceDemographicRef,
  } = ref;

  const [filters, setFilters] = React.useState({
    productName: "",
    country: "",
    age_bracket: [],
    lsm: [],
    urban_rural: [],
    startDate: new Date(),
    endDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
    gender: [],
    months: "",
    productCount: "",
    metrics: "",
  });
  const [country, setCountry] = React.useState("");
  const [productName, setProductName] = React.useState("");

  let month;
  if (filters.months === 3) {
    month = "3 months";
  } else if (filters.months === 6) {
    month = "6 months";
  } else if (filters.months === 9) {
    month = "9 months";
  } else {
    month = "3 months";
  }

  if (country === "" && productName === "") {
    // eslint-disable-next-line array-callback-return
    setCountry(campaign?.campaign?.country);
    setProductName(campaign?.campaign?.product_category);
  }
  React.useEffect(() => {
    setFilters({
      ...filters,
      country: campaign?.campaign?.country ?? "",
      productName: campaign?.campaign?.product_category ?? "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaign]);

  return (
    <>
      {filters.country !== "" && filters.productName !== "" ? (
        <div>
          <Box>
            <MarketPenetration
              ref={marketPenetrationRef}
              month={month}
              filters={filters}
            />
          </Box>
          <br />
          <Box>
            <AudienceMediaConsumption
              ref={audienceMediaConsumptionRef}
              filters={filters}
            />
          </Box>
          <br />
          <Box>
            <AudienceDemographic
              ref={audienceDemographicRef}
              filters={filters}
            />
          </Box>
        </div>
      ) : null}
    </>
  );
});

export default Audience;
