import { getAudience } from "api/audience";
import React from "react";

export const useGet = (filters, path) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState([]);
  const [error, setError] = React.useState("");
  const [series, setSeries] = React.useState([]);

  const fetchData = async () => {
    setLoading(true);
    let response;
    try {
      response = await getAudience(filters, path);
      if (response.status === 200) {
        setData(response.data);
        setLoading(false);
      } else if (response.status === 500 && response.data.insufficientData) {
        setError("There was an error loading data");
        setLoading(false);
      }
    } catch (e) {
      setError(e.response, "error");
      setData([]);
      setLoading(false);
    }

    if (response.status === 200 && !response.data.insufficientData) {
      let seriesA = [];
      Object.entries(response.data).forEach(([key]) => {
        if (response.data[key].length > 0) {
          let elem = transformDataset(response.data[key]);
          seriesA.push({ serie: elem, title: key });
        }
      });
      setSeries(seriesA);
      setLoading(false);
    }

    return response;
  };

  const transformDataset = (data) => {
    let formattedData = [];
    for (let i = 0; i <= data.length; i++) {
      if (data[i]) {
        formattedData.push({
          name: data[i]["product_service"] || data[i]["group_key"],
          y: data[i]["proportions"] * 100,
        });
      }
    }
    return formattedData;
  };

  React.useEffect(() => {
    setTimeout(() => {
      if (loading) {
        fetchData();
      }
    }, 1000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, series, error, loading };
};
