import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function Footer({ data, ...rest }) {
  const classes = useStyles();
  let accordion;

  const accordionDetails = data;
  if (accordionDetails) {
    accordion = (
      <Accordion square expanded={true}>
        <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
          <Typography className={classes.heading}>
            <strong>Definitions</strong>
          </Typography>
        </AccordionSummary>
        <Typography variant='subtitle1'>
          {accordionDetails.map((i) => (
            <AccordionDetails key={i.name}>
              <p>
                <strong>{i.name}</strong>: {i.description}
              </p>
            </AccordionDetails>
          ))}
        </Typography>
      </Accordion>
    );
  }

  return (
    <div className={classes.root}>
      {accordion}
      <br />
      <Typography variant='body1'>
        &copy; All rights reserved. Mdundo.com AS
      </Typography>
    </div>
  );
}
