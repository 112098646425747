import { apiUrl } from "api/api";
import axios from "axios";
import React from "react";
import useGetReq from "./useGetReq";

function useBrand(filters) {
  const [brand, setBrand] = React.useState({});
  const [data, setData] = React.useState([]);

  let oneYearAvgPerfData = [];
  let campaignData = [];

  if (brand) {
    Object.entries(brand).forEach((k) => {
      if (k.find((el) => el === "oneYearAvgPerf")) {
        // eslint-disable-next-line no-redeclare
        var i;
        for (i = 0; i < k.length; i++) {
          const gt = {};
          let kt = [k.pop()];

          gt.tg = [
            {
              name: "Brand Lift",
              data: kt[0].brandlift,
              definition: `The overall performance of a brand
        within the Mdundo Audience including awareness, favourability,
        consideration and purchase intent.`,
            },
            {
              name: "Awareness",
              data: kt[0].awareness,
              definition: `Measures if the Mdundo audience is
        aware of the brand relative to competing brands. Research
        Questions: Which of the following brand names have you heard of?`,
            },
            {
              name: "Consideration",
              data: kt[0].consideration,
              definition: `Consideration measures if the
        Mdundo audience would consider purchasing the brand relative to
        competing brands. Research question: Which of these cooking oils
        would you consider purchasing?`,
            },
            {
              name: "Favourability",
              data: kt[0].favourability,
              definition: `Favourability measures if the Mdundo
        audience favour the brand relative to competing brands. Research
        Question:Which of the following cooking oils do you have a
        positive opinion of?`,
            },
            {
              name: "Purchase Intent",
              data: kt[0].intent,
              definition: `Purchase intent measures if the
        Mdundo audience will purchase the brand at the next point of
        purchase. Research Question: Next time you buy cooking oil, which
        of the following are you likely to buy?`,
            },
          ];
          oneYearAvgPerfData.push(gt);
        }
      } else if (k.find((el) => el === "campaign")) {
        campaignData.push(k.pop());
      }
    });
  }

  const fetchBrandAudience = async (filters) => {
    const params = {};

    if (filters?.country) {
      params.country = '"' + filters?.country + '"';
    }

    try {
      const response = await axios.get(`${apiUrl.baseUrl}/ga/audience/size?`, {
        params,
      });
      if (response.status === 200 && !response.insufficientData) {
        setData(response.data);
      } else {
        return;
      }
    } catch (err) {
      return err.response;
    }
  };

  React.useEffect(() => {
    fetchBrandAudience(filters);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getData } = useGetReq();

  React.useEffect(() => {
      getData(
        `${apiUrl.baseUrl}/promo/avg-performance`,
        setBrand
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { oneYearAvgPerfData, data };
}

export default useBrand;
