export const categories = [
  {
    name: "Food & Drinks",
    subcategories: [
      {
        label: "Beer",
        key: "beer",
      },
      {
        label: "Gin",
        key: "gin",
      },
      {
        label: "Spirits",
        key: "spirits",
      },
      {
        label: "Soft Drinks",
        key: "soft_drinks",
      },
      {
        label: "Bottled Water",
        key: "water",
      },
      {
        label: "Cooking Oil",
        key: "cooking_oil",
      },
      {
        label: "Cooking Spices",
        key: "cooking_spices",
      },
      {
        label: "Flour",
        key: "flour",
      },
      {
        label: "Chocolate",
        key: "chocolate",
      },
      {
        label: "Baby Foods",
        key: "baby_food",
      },
      {
        label: "Caffeine (Coffee)",
        key: "coffee",
      },
      {
        label: "Chewing Gum",
        key: "gum",
      },
    ],
  },
  {
    name: "Connectivity",
    subcategories: [
      {
        label: "Telecom",
        key: "airtime",
      },
      {
        label: "Phone",
        key: "phone",
      },
      {
        label: "TV Providers",
        key: "tv",
      },
    ],
  },
  {
    name: "Financial Services",
    subcategories: [
      {
        label: "Banking",
        key: "bank",
      },
      {
        label: "Loan Service",
        key: "loan",
      },
      {
        label: "Insurance",
        key: "insurrance",
      },
      {
        label: "Mobile Money",
        key: "mobile_money",
      },
      {
        label: "Betting Service",
        key: "sportsbetting",
      },
    ],
  },
  {
    name: "Personal Care",
    subcategories: [
      {
        label: "Toothpaste",
        key: "toothpaste",
      },
      {
        label: "Deodorant",
        key: "deodorant",
      },
      {
        label: "Body Lotion",
        key: "lotion",
      },
      {
        label: "Bath Soap & Shower Gel",
        key: "soap",
      },
      {
        label: "Baby Care",
        key: "baby_products",
      },
      {
        label: "Contraceptives",
        key: "contraceptives",
      },
      {
        label: "Cosmetics",
        key: "cosmetic_products",
      },
      {
        label: "Lozenges",
        key: "soar_throat",
      },
      {
        label: "Reflux Suppressants",
        key: "stomach_acidity",
      },
    ],
  },
  {
    name: "Fabric and Home Care",
    subcategories: [
      {
        label: "Laundry Powder",
        key: "laundry_powder",
      },
      {
        label: "Dishwashing Liquid",
        key: "dishwashing_liquids",
      },
    ],
  },
];

export const countries = ["Kenya", "Tanzania", "Ghana", "Uganda", "Nigeria"];
export const ageBrackets = ["18-24", "25-34", "35-44", "Over 45"];

export const locations = ["Urban", "Rural"];

export const lsmOptions = [
  { label: "Mass Market", key: "Mass Market" },
  { label: "Upper Middle Market", key: "Upper Middle Market" },
  { label: "Wealthy Market", key: "Wealthy Market" },
];

export const gender = [
  {
    label: "Male",
    key: "male",
  },
  {
    label: "Female",
    key: "female",
  },
];

export const months = [
  {
    label: "3 Months",
    key: 3,
  },
  {
    label: "6 Months",
    key: 6,
  },
  {
    label: "9 Months",
    key: 9,
  },
];
export const productCount = [
  { label: "All Brands", key: 1000 },
  { label: "Top 10 Brands", key: 10 },
  { label: "Top 20 Brands", key: 20 },
];

export const metrics = [
  { label: "Awareness", key: "awareness" },
  { label: "Consideration", key: "consideration" },
  { label: "Favourability", key: "favourability" },
  { label: "Purchase Intent", key: "intent" },
  { label: "Brand lift", key: "brandlift" },
];

export const definitions = [
  {
    name: "Category",
    description: `A set of product / services in the same
    segment i.e Food and Drinks, Telcos`,
  },
  {
    name: "Products",
    description: `Goods or services in a specific
    category offered to a market to satisfy a want or need.`,
  },
  {
    name: "Country",
    description: ` A nation or territory where Mdundo
    audience exists and surveys done on them.`,
  },
  {
    name: "Location",
    description: `Urban-Rural classification where Urban
    refers to the developed areas, cities and major towns while rural
    area refers to the rest of regions consisting of smaller towns and
    countryside.`,
  },
  {
    name: "Age",
    description: `The length of time that a person has lived
    or a thing has existed ranging between 15 and 55 with age groups
    of belore 15, 15-24, 25 - 34, 35-44, 45-54, 55 and Above`,
  },
  {
    name: "LSM",
    description: `Classification of living standard and
    disposable income ranging from Low Income (LSM 1-4 ) upto $250 ,
    Middle (5-9 ) between $250 and $1,200 , High (10-12) over $1,200
    based on income and spend.`,
  },
  {
    name: "Brand Lift",
    description: `The overall performance of a brand
    within the Mdundo Audience including awareness, favorability,
    consideration and purchase intent.`,
  },
  {
    name: "Awareness",
    description: `Measures if the Mdundo audience is
    aware of the brand relative to competing brands. Research
    Questions: Which of the following brand names have you heard of?`,
  },
  {
    name: "Favourability",
    description: `Favourability measures if the Mdundo
    audience favour the brand relative to competing brands. Research
    Question:Which of the following cooking oils do you have a
    positive opinion of?`,
  },
  {
    name: "Consideration",
    description: `Consideration measures if the
    Mdundo audience would consider purchasing the brand relative to
    competing brands. Research question: Which of these cooking oils
    would you consider purchasing?`,
  },
  {
    name: "Purchase Intent",
    description: `Purchase intent measures if the
    Mdundo audience will purchase the brand at the next point of
    purchase. Research Question: Next time you buy cooking oil, which
    of the following are you likely to buy?`,
  },
];
