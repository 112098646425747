import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  Paper,
} from "@material-ui/core";
import Radio from "./components/Radio";
import TV from "./components/TV";
import { Alert } from "@material-ui/lab";
import SocialMedia from "./components/SocialMedia";
import { useGet } from "hooks/useGet";
import MusicService from "./components/MusicService";

const AudienceMediaConsumption = React.forwardRef(
  ({ filters, className, audience }, ref) => {
    const { series, error, loading } = useGet(filters, "consumption/media");

    // const { ref } = rf;
    // console.log(ref);

    return (
      <>
        {loading ? (
          <div className={className}>
            <CircularProgress disableShrink />
          </div>
        ) : (
          <Card>
            <CardHeader
              style={{}}
              titleTypographyProps={
                audience && {
                  component: "div",
                  variant: "h5",
                  style: { padding: 0, margin: 0 },
                }
              }
              title="Media Habits (%) within the Mdundo Audience, last 7 days"
            />
            <Divider />
            <CardContent>
              <div ref={ref}>
                <Grid container spacing={4}>
                  <Grid item lg={6} sm={6} xl={6} xs={12}>
                    <Paper>
                      <Radio series={series} loading={loading} />
                    </Paper>
                  </Grid>
                  <Grid item lg={6} sm={6} xl={6} xs={12}>
                    <Paper>
                      <TV series={series} loading={loading} />
                    </Paper>
                  </Grid>
                  <Grid item lg={6} sm={6} xl={6} xs={12}>
                    <Paper>
                      <SocialMedia series={series} loading={loading} />
                    </Paper>
                  </Grid>
                  <Grid item lg={6} sm={6} xl={6} xs={12}>
                    <Paper>
                      <MusicService series={series} loading={loading} />
                    </Paper>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </Card>
        )}
        {error && <Alert severity="error">{error}</Alert>}
      </>
    );
  }
);

export default AudienceMediaConsumption;
