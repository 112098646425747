import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import { Parallax } from "react-parallax";
import { Element } from "react-scroll";

const useStyles = makeStyles(theme => ({
  insideStyles: {
    fontSize: 20,
    paddingBottom: 50,
  },
  contact: {
    paddingTop: 100,
    paddingBottom: 100,
    [theme.breakpoints.up("sm")]: {
      paddingTop: 200,
      paddingLeft: 200,
      paddingRight: 200,
      paddingBottom: 200,
    },
    paddingLeft: 20,
    paddingRight: 20,
  },
  text: {
    fontSize: 16,
    marginBottom: 18,
  },
}));

const Contact = () => {
  const classes = useStyles();

  return (
    <Element id="contact" name="contact">
      <Parallax strength={700} blur={10} className={classes.contact}>
        <div className={classes.insideStyles}>
          <Typography gutterBottom variant="h3">
            CONTACT US
          </Typography>
          <Typography gutterBottom variant="h5">
            NAME
          </Typography>
          <Typography gutterBottom variant="body1" className={classes.text}>
            Mdundo Name
          </Typography>
          <Typography gutterBottom variant="h5">
            COMPANY
          </Typography>
          <Typography gutterBottom variant="body1" className={classes.text}>
            Mdundo
          </Typography>

          <Typography gutterBottom variant="h5">
            EMAIL
          </Typography>
          <Typography gutterBottom variant="body1" className={classes.text}>
            mdundo@brandlift.com
          </Typography>

          <Typography gutterBottom variant="h5">
            CONTACT
          </Typography>
          <Typography gutterBottom variant="body1" className={classes.text}>
            +254000000000
          </Typography>
        </div>
      </Parallax>
    </Element>
  );
};

export default Contact;
