import { Reporting } from "pages";
import React from "react";
import { Switch, Redirect } from "react-router-dom";
import Login from "views/Login/Login";

import { RouteWithLayout, RouteWithLogin } from "./components";
// eslint-disable-next-line no-unused-vars
import { Minimal as MinimalLayout } from "./layouts";

import {
  Dashboard as DashboardView,
  NotFound as NotFoundView,
  // Home as HomeView
} from "./views";

const Routes = () => {
  return (
    <Switch>
      {/* <RouteWithLayout
        component={HomeView}
        exact
        layout={MinimalLayout}
        path="/"
      /> */}
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MinimalLayout}
        path='/'
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path='/not-found'
      />
      <RouteWithLogin
        exact
        path='/reporting'
        component={Reporting}
        layout={MinimalLayout}
      />
      <RouteWithLayout
        exact
        path='/login'
        component={Login}
        layout={MinimalLayout}
      />
      <Redirect to='/not-found' />
    </Switch>
  );
};

export default Routes;
