import { makeStyles } from "@material-ui/styles";
import React from "react";
import {
  Link,
  NavLink as RouterLink,
  Route,
  useLocation,
} from "react-router-dom";
import { Tab, Tabs, Button, Toolbar, Grid } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  button: { color: "red" },
});

const CustomRouterLink = React.forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

function Navbar() {
  const classes = useStyles();
  const location = useLocation();

  //   handlers
  const handleSubmit = (e) => {
    e.preventDefault();

    localStorage.removeItem("user");
  };

  if (location.pathname === "/reporting" || location.pathname === "/") {
    return (
      <Route
        path='/'
        render={({ location }) => (
          <Grid container spacing={1} className={classes.root}>
            <Grid item xs>
              {" "}
              <Toolbar>
                <Tabs
                  value={location.pathname}
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#D97D54",
                    },
                  }}
                >
                  <Tab
                    label='Brand Lift Tool'
                    value='/'
                    component={CustomRouterLink}
                    to='/'
                  />
                  <Tab
                    label='Reporting'
                    value='/reporting'
                    component={CustomRouterLink}
                    to='/reporting'
                  />
                </Tabs>
              </Toolbar>
            </Grid>
            <Grid item>
              <Toolbar>
                {localStorage.getItem("user") ? (
                  <Button
                    className={classes.button}
                    variant='contained'
                    onClick={handleSubmit}
                  >
                    <Link to='/login'>Logout</Link>
                  </Button>
                ) : null}
              </Toolbar>
            </Grid>
          </Grid>
        )}
      />
    );
  } else {
    return (
      <>
        {localStorage.getItem("user") ? (
          <div className={classes.root}>
            <Toolbar></Toolbar>
            <Toolbar>
              <Button
                className={classes.button}
                variant='contained'
                onClick={handleSubmit}
              >
                <Link to='/login'>Logout</Link>
              </Button>
            </Toolbar>
          </div>
        ) : null}
      </>
    );
  }
}

export default Navbar;
