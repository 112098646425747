import React from "react";
const { default: axios } = require("axios");

const useGetReq = () => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState("");
  const [data, setData] = React.useState({});
  const cancelToken = axios.CancelToken.source();

  const authHeader = localStorage.getItem("user")?.replace(/['"]+/g, "");

  const getData = (path, onComplete, onError, reqParams) => {
    return new Promise((resolve, reject) => {
      setLoading(false);
      axios
        .get(path, {
          cancelToken: cancelToken.token,
          headers: {
            Authorization: `Basic ${authHeader}`,
          },
          ...reqParams,
        })
        .then((res) => {
          if (onComplete && res.status === 200 && !res.data.insufficientData) {
            onComplete(res?.data);
          } else if (onComplete && res.data.insufficientData) {
            onComplete(null);
            setLoading(false);
            setError("Not enough data! Try another campaign");
          }
          if (res.status === 200) {
            setData(res?.data);
          }

          resolve(res);
        })
        .catch((error) => {
          if (onError) {
            setLoading(false);
            onError(error.message);
            setError("There is a problem!");
          }

          setError(`${error.message}`);
        });
    });
  };
  const cancelRequests = () => {
    if (cancelToken) {
      cancelToken.cancel(" ");
    }
  };

  return { getData, loading, error, cancelRequests, data };
};

export default useGetReq;
