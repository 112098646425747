import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { Alert } from "@material-ui/lab";
import { useGet } from "hooks/useGet";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "1rem",
    "&>span": {
      backgroundColor: "yellow",
    },
  },
}));

function AudienceSize({ filters, className }) {
  const myRef = React.useRef(null);
  const classes = useStyles();
  const { data, error, loading } = useGet(filters, "ga/audience/size");

  return (
    <>
      {loading ? (
        <div className={className}>
          <CircularProgress disableShrink />
        </div>
      ) : (
        <>
          {" "}
          <Grid container spacing={4} ref={myRef}>
            <Grid item xs={12}>
              <Paper>
                <Typography
                  variant='h4'
                  align='center'
                  className={classes.root}
                >
                  The selected audience includes{" "}
                  <span>{data.unique_users}</span> unique users served a total
                  of <span>{data.audio_ads}</span> audio ads in the last 90
                  days.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          {error && !loading && <Alert severity='error'>{error}</Alert>}
        </>
      )}
    </>
  );
}

export default AudienceSize;
