import { CircularProgress } from "@material-ui/core";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";

export default function Radio({ series, loading }) {
  return (
    <div>
      {loading ? (
        <div>
          <CircularProgress disableShrink />
        </div>
      ) : (
        <>
          {series.map((chart, key) => {
            if (chart.title === "radio_station") {
              //   console.log(chart.serie[0].name);
              var labels = [];
              let entries = chart.serie;
              const data = entries.sort((a, b) => b.y - a.y);
              Object.values(data).forEach((i) => labels.push(i.name));

              const options = {
                chart: {
                  type: "column",
                },
                credits: {
                  enabled: false,
                },
                title: {
                  text: chart?.title?.replace(/_/g, " ").toUpperCase(),
                },

                xAxis: {
                  categories: labels,
                  crosshair: true,
                },
                yAxis: {
                  min: 0,
                  title: {
                    text: "Radio %",
                  },
                },
                tooltip: {
                  valueSuffix: "%",
                  headerFormat: null,
                  pointFormat: "<b>{point.name}</b>: {point.y}",
                },
                plotOptions: {
                  column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                  },
                },
                series: [
                  {
                    dataSorting: {
                      enabled: true,
                    },
                    showInLegend: false,
                    data: chart.serie,
                  },
                ],
              };
              return (
                <HighchartsReact
                  highcharts={Highcharts}
                  key={key}
                  options={options}
                  onChartReady={() => {
                    chart.serie.forEach((point) => {
                      point.name = point.name.toUpperCase();
                    });
                  }}
                />
              );
            }
            return null;
          })}
        </>
      )}
    </div>
  );
}
